/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Logo from "../../../../logo.png";
const img1 = require('../../../../img1.jpg'); // Using require to load image

const VideoSection = () => {
    const theme = useTheme();

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        maxWidth: 450,
                        position: 'relative',
                        marginX: 'auto',
                        display: 'flex',
                        justifyContent: 'flex-start', // Align image to the left
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            borderRadius: '2.75rem',
                            boxShadow: 1,
                            width: '75% !important',
                        }}
                    >
                        <Box>
                            <Box
                                position={'relative'}
                                top={'2.4%'}
                                width={'92.4%'}
                                height={'96%'}
                            >
                                <img
                                    src={img1}
                                    alt="Skilled Strategies Logo"
                                    style={{
                                        width: '150%', // Make the image take the full width of its container
                                        height: 'auto', // Adjust height automatically based on width
                                        objectFit: 'cover', // Crop the image
                                        objectPosition: 'center bottom', // Focus on the center of the image
                                        maxWidth: '500px', // Limit the max width of the image to make it smaller
                                        borderRadius: '10px', // Optional: Add some border-radius to match the design
                                        marginLeft: 0, // Ensure the image stays left-aligned
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item container alignItems={'center'} xs={12} md={6}>
                <Box sx={{ paddingTop: 3 }}> {/* Adding padding here */}
                    <Box marginBottom={2}>
                        <Typography
                            variant="h4"
                            color="text.primary"
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            Skilled Strategies: Helping Your Business Grow
                        </Typography>
                    </Box>
                    <Box marginBottom={3}>
                        <Typography variant="h6" component="p" color="text.secondary">
                            Discover how we can help your small business thrive. From leads to
                            growth strategies, Skilled Strategies is your partner in success.
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default VideoSection;