import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Logo from '../../../../../../logo.png';  // Import your logo here

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
      <Box>
        <Box width={1} paddingX={2} paddingY={1}>
          <Box
              display={'flex'}
              component="a"
              href="/"
              title="Skilled Strategies"
              width={{ xs: 100, md: 120 }}
          >
            <Box
                component={'img'}
                src={Logo}  // Use the same logo as in the Topbar
                height={1}
                width={1}
            />
          </Box>
        </Box>
        <Box paddingX={2} paddingY={2}>
          {/* The same navigation items as in the Topbar */}
          {/*<Box>*/}
          {/*  <Button*/}
          {/*      variant="text"*/}
          {/*      color="inherit"*/}
          {/*      component="a"*/}
          {/*      href="/products"*/}
          {/*      size="large"*/}
          {/*      fullWidth*/}
          {/*      style={{ color: 'black', justifyContent: 'flex-start' }}*/}
          {/*  >*/}
          {/*    Our Products*/}
          {/*  </Button>*/}
          {/*</Box>*/}
          <Box marginTop={2}>
            <Button
                variant="contained"
                color="primary"
                component="a"
                href="/contact"
                size="large"
                fullWidth
                style={{ justifyContent: 'flex-start' }}
            >
              Contact Us
            </Button>
          </Box>
        </Box>
      </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;