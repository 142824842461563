import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Main from '../../layouts/Main';
import Container from '../../components/Container';
import {
    About,
    InfoWithImage2,
    Features,
    Team,
    VideoSection,
} from './components';

const Service = () => {
    const theme = useTheme();
    return (
        <Main>
            <Box
                sx={{
                    backgroundColor: theme.palette.alternate?.main || '#f7faff', // Fallback value
                    backgroundImage: `linear-gradient(120deg, ${theme.palette.background?.paper || '#ffffff'} 0%, ${theme.palette.alternate?.main || '#f7faff'} 100%)`,
                    position: 'relative',
                    marginTop: -13,
                    paddingTop: 13,
                }}
            >
                <Container position={'relative'} zIndex={3}>
                    <About />
                </Container>
                <Box
                    component={'svg'}
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 1921 273"
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 1,
                        height: '35%',
                    }}
                >
                    <polygon
                        fill={theme.palette.background?.paper || '#ffffff'} // Fallback value
                        points="0,273 1921,273 1921,0 "
                    />
                </Box>
            </Box>
            <Container>
                <VideoSection />
            </Container>
            {/*<Box bgcolor={theme.palette.alternate?.main || '#f7faff'}>*/}
            {/*    <Container>*/}
            {/*        <Advantages />*/}
            {/*    </Container>*/}
            {/*</Box>*/}
            <Box bgcolor={theme.palette.alternate?.main || '#f7faff'}>
                <Container>
                    <Features/>
                </Container>
            </Box>

            <Container>
                <InfoWithImage2 />
            </Container>
            <Divider />
            {/*<Container>*/}
            {/*    <Reviews />*/}
            {/*</Container>*/}
            {/*<Container paddingTop={'0 !important'}>*/}
            {/*    <Integrations />*/}
            {/*</Container>*/}
            <Box bgcolor={theme.palette.alternate?.main || '#f7faff'}>
                <Container>
                    <Team />
                </Container>
            </Box>
        </Main>
    );
};

export default Service;