/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';

import Container from '../../../../components/Container';

const mock = [
    {
        title: 'Brand Visibility for Blue-Collar Industries',
        subtitle:
            'In an increasingly digital world, standing out is crucial. We help you enhance your brand’s visibility through proven marketing techniques, ensuring your business reaches the customers who matter. From website optimization to local SEO strategies, we make sure your services are easily found by those who need them most.',
        icon: (
            <svg
                width={40}
                height={40}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                />
            </svg>
        ),
    },
    {
        title: 'Strategic Leadership for Sustainable Growth',
        subtitle:
            'At Skilled Strategies, we work closely with your business to develop leadership strategies that create a solid foundation for long-term success. Through our one-on-one consultations and workshops, we provide you with the tools and knowledge to take your business to the next level.\n',
        icon: (
            <svg
                width={40}
                height={40}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                />
            </svg>
        ),
    },
    {
        title: 'Connecting You with Industry Leaders',
        subtitle:
            'Whether you’re looking to network with other blue-collar business owners or connect with suppliers and industry influencers, we create the opportunities that matter. From local events to online communities, we help you build the relationships that will drive your business forward.\n',
        icon: (
            <svg
                width={40}
                height={40}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
            </svg>
        ),
    },
    {
        title: 'Driving Lead Generation and Business Growth',
        subtitle:
            'Leads are the lifeblood of your business. We specialize in creating targeted lead generation strategies tailored to your specific services. From increasing your online presence to refining your customer outreach, we help you attract and retain clients who will keep your business growing.',
        icon: (
            <svg
                width={40}
                height={40}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                />
            </svg>
        ),
    },
];

const Features = () => {
    const theme = useTheme();

    return (
        <Box sx={{ position: 'relative' }}> {/* Full-width background */}
            <Container>
                <Box>
                    <Box marginBottom={4}>
                        <Typography
                            sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'medium',
                            }}
                            gutterBottom
                            color={'text.secondary'}
                            align={'center'}
                        >
                            Why Partner with Skilled Strategies
                        </Typography>
                        <Typography
                            variant="h4"
                            align={'center'}
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            Unlock growth opportunities for your blue-collar business with tailored consulting services.
                        </Typography>
                        <Typography variant="h6" align={'center'} color={'text.secondary'}>
                            Our mission is to help your business grow, attract new clients, and
                            boost your bottom line
                            <br/>
                            whether you’re in landscaping, plumbing, or
                            any other trade.
                            <br />
                            At Skilled Strategies, we focus on delivering practical
                            strategies to elevate your business, ensuring you reach
                            your full potential through actionable insights and sustainable
                            business growth.
                        </Typography>
                    </Box>
                    <Grid container spacing={4}>
                        {mock.map((item, i) => (
                            <Grid key={i} item xs={12} md={3}>
                                <ListItem
                                    component="div"
                                    disableGutters
                                    sx={{
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        padding: 0,
                                    }}
                                >
                                    <Box
                                        component={ListItemAvatar}
                                        marginBottom={1}
                                        minWidth={'auto !important'}
                                    >
                                        <Box color={theme.palette.primary.main}>{item.icon}</Box>
                                    </Box>
                                    <ListItemText
                                        primary={item.title}
                                        secondary={item.subtitle}
                                        primaryTypographyProps={{
                                            variant: 'h6',
                                            gutterBottom: true,
                                            align: 'left',
                                        }}
                                        secondaryTypographyProps={{ align: 'left' }}
                                        sx={{
                                            '& .MuiListItemText-primary': {
                                                fontWeight: 700,
                                            },
                                            margin: 0,
                                        }}
                                    />
                                </ListItem>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default Features;